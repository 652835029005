@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600;700&display=swap");

:root {
	--background: #070707;
	--blue: #0044ff;

	--grey1: #0f0f0f;
	--grey2: #181818;
	--grey3: #282828;
	--grey4: #3a3a3a;

	--grey1: #010101;
	--grey2: #090909;
	--grey3: #171717;
	--grey4: #1e1e1e;
	--grey5: #2e2e2e;

	--blueGradient: linear-gradient(90deg, #0088ff, #0000ff);

	--entityType0Colour: #0044ff;
	--entityType1Colour: #ff0055;
	--entityType2Colour: #27594d;
}

body {
	margin: 0;
	overflow-y: overlay;
	overflow-x: hidden;

	background: var(--background);

	font-family: "Calibri", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

body::-webkit-scrollbar {
	border-radius: 4px;
	width: 7px;
	background: var(--grey2);
	transition: 0.2s;
}

body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}

.App {
	text-align: center;
}
