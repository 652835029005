.page-not-complete-container {
	margin: 0 auto;
	width: 98%;
	max-width: 800px;
	text-align: center;
	font-size: 1.1rem;
}

.page-not-complete-container svg {
	margin: 20px auto;
	margin-top: 80px;
	font-size: 7rem;
}
