@import url("https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

.landing-front {
	position: relative;

	display: flex;
	justify-self: center;
	align-self: center;

	width: 100vw;
	height: 100vh;

	background: url("../../assets/initialGalaxySide.png");
	background-position: center;
	background-size: cover;
}

.landing-front-title {
	margin: auto;
	transform: translateY(-25%);

	text-align: center;
	text-transform: uppercase;
	font-size: 5.5rem;
	font-family: "Orbitron", sans-serif;
	font-family: "Palatino Linotype", sans-serif;
	font-weight: 400;
}

.landing-front-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -59%);

	width: 40%;
	max-width: 300px;

	filter: brightness(0) invert(1) opacity(0.3);
	user-select: none;
	-webkit-user-drag: none;
}

@media (max-width: 1000px) {
	.landing-front-title {
		transform: translateY(-25%);
		font-size: 4rem;
	}

	.landing-front-logo {
		width: 25%;
		transform: translate(-50%, calc(-50% - 20px));
	}
}

@media (max-width: 750px) {
	.landing-front-title {
		transform: translateY(-25%);
		font-size: 2.5rem;
	}

	.landing-front-logo {
		width: 30%;
		transform: translate(-50%, calc(-50% - 10px));
	}
}

.landing-info {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1200px;
	min-height: 150vh;
}

.landing-info-title {
	margin: 0 auto;
	padding-top: 60px;
	margin-bottom: 30px;

	width: 100%;

	text-align: left;
	font-size: 2rem;
	font-weight: 600;
}

.landing-info-description {
	margin: 60px auto;
	margin-top: 0;

	width: 100%;

	text-align: left;
}
