.locations-country-cards-container {
	margin: 0 auto;

	width: 100%;

	text-align: left;
}

.locations-country-cards-container {
	position: relative;
	display: flex;
	margin-left: -4px;
	margin-bottom: 60px;
	padding-bottom: 24px;

	width: calc(100% + 8px);

	text-align: left;
	overflow-x: overlay;
}

.locations-country-cards-container::-webkit-scrollbar {
	border-radius: 4px;

	width: calc(100% - 8px);
	height: 8px;

	background: var(--grey2);
	transition: 0.2s;
}

.locations-country-cards-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}
