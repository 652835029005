.landing-character-card {
	position: relative;
	display: inline-block;
	flex-shrink: 0;

	margin: 0 4px;
	padding: 0;
	border: 5px solid var(--grey2);
	border-radius: 12px;

	width: 290px;
	height: 400px;

	background: var(--grey1);
	transition: 0.3s;
	cursor: pointer;
}

.landing-character-card {
	margin-left: -50px;
}

.landing-character-card:first-child {
	margin-left: 4px;
}

.landing-character-card:hover + .landing-character-card {
	margin-left: 4px;
}

.landing-character-card-background-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	border: 5px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

.landing-character-card-background-colour {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	border: 5px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

.landing-character-card-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	width: calc(100% - 20px);
	height: calc(100% - 20px);

	object-fit: cover;
}

.landing-character-card-top {
	display: block;
	margin: 0 auto;

	width: calc(100% - 20px);
}

.landing-character-card-top-name {
	display: inline-block;

	width: fit-content;
	height: 35px;

	vertical-align: middle;
	font-size: 1.4rem;
	font-weight: 700;
	font-family: "Exo 2", sans-serif;
}

.landing-character-card-top-entity-type {
	position: relative;
	margin: 0;
	margin-top: 2px;
	padding: 0 10px;
	border-radius: 16px;
	float: right;

	height: 20px;

	background: var(--grey2);
	font-size: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	vertical-align: middle;
}

.landing-character-card-top-entity-type p {
	margin: 1px 0;

	vertical-align: middle;
}

.landing-character-card-top-entity-type-0 {
	background: var(--entityType0Colour);
}

.landing-character-card-top-entity-type-1 {
	background: var(--entityType1Colour);
}

.landing-character-card-top-entity-type-2 {
	background: var(--entityType2Colour);
}

.landing-character-card-info-container {
	margin-top: 148px;
	width: 100%;
}

.landing-character-card-info {
	margin: 8px 0;
	padding: 6px 12px;
	border-radius: 8px;
	background: var(--grey2);
}

.landing-character-card-info p {
	margin: 0;
}

.landing-character-card-info-label {
	font-size: 12px;
	font-weight: 200;
}

.landing-character-card-info-value {
	font-size: 13px;
	font-weight: 600;
}
