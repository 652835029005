.page-top-bar-space {
	display: block;
	width: 100%;
	height: 70px;
}

.page-title {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 20px 0;
	padding: 0 40px;
	border-radius: 8px;

	width: calc(100% - 80px);
	height: 110px;

	background: var(--blueGradient);
	color: #fff;
	text-align: left;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.page-title {
		padding: 0 20px;
		width: calc(100% - 40px);
		height: 90px;
	}
}

.page-title h1 {
	display: flex;
	justify-self: center;
	vertical-align: middle;
	margin: 0;
	margin-top: -12px;
	font-size: 50px;
	text-align: left;
}

@media (max-width: 750px) {
	.page-title h1 {
		margin-top: -5px;
		font-size: 40px;
	}
}

.page-subtitle {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 32px 0;
	padding: 0 8px;
	border-radius: 8px;

	color: #fff;
	text-align: left;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.page-subtitle {
		margin: 24px 0;
	}
}

.page-subtitle h2 {
	display: flex;
	justify-self: center;
	vertical-align: middle;
	margin: 0;
	margin-top: -4px;
	font-size: 40px;
	text-align: left;
}

@media (max-width: 750px) {
	.page-subtitle h2 {
		margin-top: -4px;
		font-size: 30px;
	}
}
