.characters-character-cards-container {
	margin: 0 auto;

	width: 100%;

	text-align: left;
}

.characters-character-cards-title {
	margin: 20px 0;
	font-size: 1.5rem;
	font-weight: 600;
}

.characters-character-cards {
	position: relative;
	display: flex;
	margin-left: -4px;
	margin-bottom: 60px;
	padding-bottom: 24px;

	width: calc(100% + 8px);

	text-align: left;
	overflow-x: overlay;
}

.characters-character-cards::-webkit-scrollbar {
	border-radius: 4px;

	width: calc(100% - 8px);
	height: 8px;

	background: var(--grey2);
	transition: 0.2s;
}

.characters-character-cards::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}
