.characters-groups-container {
	margin: 20px 0;
	margin-left: -4px;

	width: calc(100% + 8px);

	text-align: left;
}

.characters-group-btn {
	position: relative;
	display: inline-block;
	flex-shrink: 1;

	margin: 4px;
	padding: 0 15px;
	border: 0;
	border-radius: 20px;

	height: 26px;
	min-width: fit-content;
	width: 20%;
	max-width: 225px;

	background: var(--grey3);
	color: #fff;
	-webkit-app-region: no-drag;
	text-align: left;
	vertical-align: middle;
	transition: background 0.2s;
	cursor: pointer;
}

@media (max-width: 750px) {
	.characters-group-btn {
		font-size: 0.9rem;
	}
}

.characters-group-btn:hover {
	background: #303030;
}

.characters-group-btn-active {
	background: var(--blue);
}

.characters-group-btn-active:hover {
	background: #005fff;
}
