.locations {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1400px;
	min-height: calc(100vh - 60px);
}

.locations-systems-btn {
	display: flex;
	justify-content: center;
	align-content: center;
	position: relative;
	flex-shrink: 0;

	margin: 0;
	border-radius: 4px;

	width: 100%;
	height: 80px;

	background: var(--grey2);
	background: url("../../assets/initialGalaxySide.png");
	background-size: 150%;
	background-position-x: left;
	background-position-y: center;

	transition: 0.2s;
}

.locations-systems-btn-hover {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey2);

	transition: 0.2s;
	opacity: 0;
}

.locations-systems-btn:hover .locations-systems-btn-hover {
	cursor: pointer;
	opacity: 0.5;
}

.locations-systems-btn-map-icon {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	font-size: 32px;
	vertical-align: middle;
}

.locations-systems-btn-name {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	font-size: 30px;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.locations-systems-btn-name {
		font-size: 22px;
	}
}

.locations-systems-btn-arrow-icon {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	margin-top: 4px;
	font-size: 28px;
	vertical-align: middle;
}
