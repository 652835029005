@import url(https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jura:wght@400;500;600;700&display=swap);
.top-bar {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 2;

	margin: 0 auto;
	padding: 0;
	border: 0;

	width: 100%;
	height: 80px;
	vertical-align: middle;
}

.top-bar-buttons {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	vertical-align: middle;
}

.top-bar-btn {
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 2px 16px;
	border: 0;
	border-radius: 100%;

	width: 45px;
	min-width: 45px;
	height: 45px;
	min-height: 45px;

	background: rgba(0, 0, 0, 0);
	color: #fff;

	font-size: 1.5rem;
	transition: 0.2s;
	vertical-align: middle;
	cursor: pointer;
}

@media (max-width: 750px) {
	.top-bar-btn {
		margin: 2px 2px;
	}
}

.top-bar-btn:hover {
	background: var(--blue);
	color: #fff;
}

.top-bar-btn svg {
	margin: auto;
	max-width: 100%;
}

.top-bar-btn-home img {
	margin: auto;
	width: 25px;
	height: 25px;

	-webkit-filter: brightness(0) invert(1);

	        filter: brightness(0) invert(1);
}

.landing-character-card {
	position: relative;
	display: inline-block;
	flex-shrink: 0;

	margin: 0 4px;
	padding: 0;
	border: 5px solid var(--grey2);
	border-radius: 12px;

	width: 290px;
	height: 400px;

	background: var(--grey1);
	transition: 0.3s;
	cursor: pointer;
}

.landing-character-card {
	margin-left: -50px;
}

.landing-character-card:first-child {
	margin-left: 4px;
}

.landing-character-card:hover + .landing-character-card {
	margin-left: 4px;
}

.landing-character-card-background-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 5px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

.landing-character-card-background-colour {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 5px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

.landing-character-card-content {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	width: calc(100% - 20px);
	height: calc(100% - 20px);

	object-fit: cover;
}

.landing-character-card-top {
	display: block;
	margin: 0 auto;

	width: calc(100% - 20px);
}

.landing-character-card-top-name {
	display: inline-block;

	width: -webkit-fit-content;

	width: -moz-fit-content;

	width: fit-content;
	height: 35px;

	vertical-align: middle;
	font-size: 1.4rem;
	font-weight: 700;
	font-family: "Exo 2", sans-serif;
}

.landing-character-card-top-entity-type {
	position: relative;
	margin: 0;
	margin-top: 2px;
	padding: 0 10px;
	border-radius: 16px;
	float: right;

	height: 20px;

	background: var(--grey2);
	font-size: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	vertical-align: middle;
}

.landing-character-card-top-entity-type p {
	margin: 1px 0;

	vertical-align: middle;
}

.landing-character-card-top-entity-type-0 {
	background: var(--entityType0Colour);
}

.landing-character-card-top-entity-type-1 {
	background: var(--entityType1Colour);
}

.landing-character-card-top-entity-type-2 {
	background: var(--entityType2Colour);
}

.landing-character-card-info-container {
	margin-top: 148px;
	width: 100%;
}

.landing-character-card-info {
	margin: 8px 0;
	padding: 6px 12px;
	border-radius: 8px;
	background: var(--grey2);
}

.landing-character-card-info p {
	margin: 0;
}

.landing-character-card-info-label {
	font-size: 12px;
	font-weight: 200;
}

.landing-character-card-info-value {
	font-size: 13px;
	font-weight: 600;
}

.landing-info-character-cards-container {
	margin: 60px auto;

	width: 100%;

	text-align: left;
}

.landing-info-character-cards-title {
	margin: 20px 0;
	font-size: 1.5rem;
	font-weight: 600;
}

.landing-info-character-cards {
	position: relative;
	display: flex;
	margin-left: -4px;
	padding-bottom: 24px;

	width: calc(100% + 8px);

	text-align: left;
	overflow-x: overlay;
}

.landing-info-character-cards::-webkit-scrollbar {
	border-radius: 4px;

	width: calc(100% - 8px);
	height: 8px;

	background: var(--grey2);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.landing-info-character-cards::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}

.landing-front {
	position: relative;

	display: flex;
	justify-self: center;
	align-self: center;

	width: 100vw;
	height: 100vh;

	background: url(/static/media/initialGalaxySide.56e7e171.png);
	background-position: center;
	background-size: cover;
}

.landing-front-title {
	margin: auto;
	-webkit-transform: translateY(-25%);
	        transform: translateY(-25%);

	text-align: center;
	text-transform: uppercase;
	font-size: 5.5rem;
	font-family: "Orbitron", sans-serif;
	font-family: "Palatino Linotype", sans-serif;
	font-weight: 400;
}

.landing-front-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -59%);
	        transform: translate(-50%, -59%);

	width: 40%;
	max-width: 300px;

	-webkit-filter: brightness(0) invert(1) opacity(0.3);

	        filter: brightness(0) invert(1) opacity(0.3);
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	-webkit-user-drag: none;
}

@media (max-width: 1000px) {
	.landing-front-title {
		-webkit-transform: translateY(-25%);
		        transform: translateY(-25%);
		font-size: 4rem;
	}

	.landing-front-logo {
		width: 25%;
		-webkit-transform: translate(-50%, calc(-50% - 20px));
		        transform: translate(-50%, calc(-50% - 20px));
	}
}

@media (max-width: 750px) {
	.landing-front-title {
		-webkit-transform: translateY(-25%);
		        transform: translateY(-25%);
		font-size: 2.5rem;
	}

	.landing-front-logo {
		width: 30%;
		-webkit-transform: translate(-50%, calc(-50% - 10px));
		        transform: translate(-50%, calc(-50% - 10px));
	}
}

.landing-info {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1200px;
	min-height: 150vh;
}

.landing-info-title {
	margin: 0 auto;
	padding-top: 60px;
	margin-bottom: 30px;

	width: 100%;

	text-align: left;
	font-size: 2rem;
	font-weight: 600;
}

.landing-info-description {
	margin: 60px auto;
	margin-top: 0;

	width: 100%;

	text-align: left;
}

.characters-groups-container {
	margin: 20px 0;
	margin-left: -4px;

	width: calc(100% + 8px);

	text-align: left;
}

.characters-group-btn {
	position: relative;
	display: inline-block;
	flex-shrink: 1;

	margin: 4px;
	padding: 0 15px;
	border: 0;
	border-radius: 20px;

	height: 26px;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	width: 20%;
	max-width: 225px;

	background: var(--grey3);
	color: #fff;
	-webkit-app-region: no-drag;
	text-align: left;
	vertical-align: middle;
	transition: background 0.2s;
	cursor: pointer;
}

@media (max-width: 750px) {
	.characters-group-btn {
		font-size: 0.9rem;
	}
}

.characters-group-btn:hover {
	background: #303030;
}

.characters-group-btn-active {
	background: var(--blue);
}

.characters-group-btn-active:hover {
	background: #005fff;
}

.characters-character-card {
	position: relative;
	display: inline-block;
	flex-shrink: 0;

	margin: 0 4px;
	padding: 0;
	border: 6px solid var(--grey2);
	border-radius: 12px;

	width: 429px;
	height: 595px;

	background: var(--grey1);
	transition: 0.3s;
	cursor: pointer;
}

@media (max-width: 750px) {
	.characters-character-card {
		border: 5px solid var(--grey2);
		width: 290px;
		height: 400px;
	}
}

.characters-character-card {
	margin-left: -215px;
}

@media (max-width: 750px) {
	.characters-character-card {
		margin-left: -50px;
	}
}

.characters-character-card:first-child {
	margin-left: 4px;
}

.characters-character-card:hover + .characters-character-card {
	margin-left: 4px;
}

.characters-character-card-background-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 6px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

@media (max-width: 750px) {
	.characters-character-card-background-image {
		border: 5px solid var(--grey2);
	}
}

.characters-character-card-background-colour {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 6px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

@media (max-width: 750px) {
	.characters-character-card-background-colour {
		border: 5px solid var(--grey2);
	}
}

.characters-character-card-content {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	width: calc(100% - 20px);
	height: calc(100% - 20px);

	object-fit: cover;
}

.characters-character-card-top {
	display: block;
	margin: 0 auto;

	width: calc(100% - 20px);
}

.characters-character-card-top-name {
	display: inline-block;

	width: -webkit-fit-content;

	width: -moz-fit-content;

	width: fit-content;
	height: 35px;

	vertical-align: middle;
	font-size: 1.8rem;
	font-weight: 700;
	font-family: "Exo 2", sans-serif;
}

@media (max-width: 750px) {
	.characters-character-card-top-name {
		font-size: 1.4rem;
	}
}

.characters-character-card-top-entity-type {
	position: relative;
	margin: 0;
	margin-top: 2px;
	padding: 0 10px;
	border-radius: 16px;
	float: right;

	height: 25px;

	background: var(--grey2);
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.characters-character-card-top-entity-type {
		height: 20px;
		font-size: 0.9rem;
	}
}

.characters-character-card-top-entity-type p {
	margin: 1px 0;

	vertical-align: middle;
}

.characters-character-card-top-entity-type-0 {
	background: var(--entityType0Colour);
}

.characters-character-card-top-entity-type-1 {
	background: var(--entityType1Colour);
}

.characters-character-card-top-entity-type-2 {
	background: var(--entityType2Colour);
}

.characters-character-card-info-container {
	margin-top: 280px;
	width: 100%;
}

@media (max-width: 750px) {
	.characters-character-card-info-container {
		margin-top: 155px;
	}
}

.characters-character-card-info {
	margin: 8px 0;
	padding: 6px 12px;
	border-radius: 8px;
	background: var(--grey2);
}

@media (max-width: 750px) {
	.characters-character-card-info-full-name {
		display: none;
	}
}

.characters-character-card-info p {
	margin: 0;
}

.characters-character-card-info-label {
	font-size: 0.9rem;
	font-weight: 200;
}

@media (max-width: 750px) {
	.characters-character-card-info-label {
		font-size: 12px;
	}
}

.characters-character-card-info-value {
	font-size: 1rem;
	font-weight: 600;
}

@media (max-width: 750px) {
	.characters-character-card-info-value {
		font-size: 13px;
	}
}

.characters-character-cards-container {
	margin: 0 auto;

	width: 100%;

	text-align: left;
}

.characters-character-cards-title {
	margin: 20px 0;
	font-size: 1.5rem;
	font-weight: 600;
}

.characters-character-cards {
	position: relative;
	display: flex;
	margin-left: -4px;
	margin-bottom: 60px;
	padding-bottom: 24px;

	width: calc(100% + 8px);

	text-align: left;
	overflow-x: overlay;
}

.characters-character-cards::-webkit-scrollbar {
	border-radius: 4px;

	width: calc(100% - 8px);
	height: 8px;

	background: var(--grey2);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.characters-character-cards::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}

.page-top-bar-space {
	display: block;
	width: 100%;
	height: 70px;
}

.page-title {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 20px 0;
	padding: 0 40px;
	border-radius: 8px;

	width: calc(100% - 80px);
	height: 110px;

	background: var(--blueGradient);
	color: #fff;
	text-align: left;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.page-title {
		padding: 0 20px;
		width: calc(100% - 40px);
		height: 90px;
	}
}

.page-title h1 {
	display: flex;
	justify-self: center;
	vertical-align: middle;
	margin: 0;
	margin-top: -12px;
	font-size: 50px;
	text-align: left;
}

@media (max-width: 750px) {
	.page-title h1 {
		margin-top: -5px;
		font-size: 40px;
	}
}

.page-subtitle {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 32px 0;
	padding: 0 8px;
	border-radius: 8px;

	color: #fff;
	text-align: left;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.page-subtitle {
		margin: 24px 0;
	}
}

.page-subtitle h2 {
	display: flex;
	justify-self: center;
	vertical-align: middle;
	margin: 0;
	margin-top: -4px;
	font-size: 40px;
	text-align: left;
}

@media (max-width: 750px) {
	.page-subtitle h2 {
		margin-top: -4px;
		font-size: 30px;
	}
}

.characters {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1400px;
	min-height: calc(100vh - 60px);
}

.page-not-complete-container {
	margin: 0 auto;
	width: 98%;
	max-width: 800px;
	text-align: center;
	font-size: 1.1rem;
}

.page-not-complete-container svg {
	margin: 20px auto;
	margin-top: 80px;
	font-size: 7rem;
}

.stories {
	margin: 0 auto;
	width: 98%;
}

.events {
	margin: 0 auto;
	width: 98%;
}

.locations-country-card {
	position: relative;
	display: inline-block;
	flex-shrink: 0;

	margin: 0 4px;
	padding: 0;
	border: 6px solid var(--grey2);
	border-radius: 12px;

	width: 429px;
	height: 595px;

	background: var(--grey1);
	text-align: left;
	transition: 0.3s;
	cursor: pointer;
}

@media (max-width: 750px) {
	.locations-country-card {
		border: 5px solid var(--grey2);
		width: 290px;
		height: 400px;
	}
}

.locations-country-card {
	margin-left: -215px;
}

@media (max-width: 750px) {
	.locations-country-card {
		margin-left: -50px;
	}
}

.locations-country-card:first-child {
	margin-left: 4px;
}

.locations-country-card:hover + .locations-country-card {
	margin-left: 4px;
}

.locations-country-card-background-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 6px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

@media (max-width: 750px) {
	.locations-country-card-background-image {
		border: 5px solid var(--grey2);
	}
}

.locations-country-card-background-colour {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	border: 6px solid var(--grey2);
	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey1);
	object-fit: cover;
	transition: 0.2s;
}

@media (max-width: 750px) {
	.locations-country-card-background-colour {
		border: 5px solid var(--grey2);
	}
}

.locations-country-card-content {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;

	width: calc(100% - 20px);
	height: calc(100% - 20px);

	object-fit: cover;
}

.locations-country-card-top {
	display: block;
	margin: 0 auto;

	width: calc(100% - 20px);
}

.locations-country-card-top-name {
	display: inline-block;

	width: -webkit-fit-content;

	width: -moz-fit-content;

	width: fit-content;
	height: 35px;

	vertical-align: middle;
	font-size: 1.8rem;
	font-weight: 700;
	font-family: "Exo 2", sans-serif;
}

@media (max-width: 750px) {
	.locations-country-card-top-name {
		font-size: 1.4rem;
	}
}

.locations-country-card-top-entity-type {
	position: relative;
	margin: 0;
	margin-top: 2px;
	padding: 0 10px;
	border-radius: 16px;
	float: right;

	height: 25px;

	background: var(--grey2);
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.locations-country-card-top-entity-type {
		height: 20px;
		font-size: 0.9rem;
	}
}

.locations-country-card-top-entity-type p {
	margin: 1px 0;

	vertical-align: middle;
}

.locations-country-card-top-entity-type-divine {
	background: var(--divineColour);
}

.locations-country-card-top-entity-type-elite {
	background: var(--eliteColour);
}

.locations-country-card-top-entity-type-outlawed-elite {
	background: var(--outlawedEliteColour);
}

.locations-country-card-info-container {
	margin-top: 280px;
	width: 100%;
}

@media (max-width: 750px) {
	.locations-country-card-info-container {
		margin-top: 155px;
	}
}

.locations-country-card-info {
	margin: 8px 0;
	padding: 6px 12px;
	border-radius: 8px;
	background: var(--grey2);
}

@media (max-width: 750px) {
	.locations-country-card-info-full-name {
		display: none;
	}
}

.locations-country-card-info p {
	margin: 0;
}

.locations-country-card-info-label {
	font-size: 0.9rem;
	font-weight: 200;
}

@media (max-width: 750px) {
	.locations-country-card-info-label {
		font-size: 12px;
	}
}

.locations-country-card-info-value {
	font-size: 1rem;
	font-weight: 600;
}

@media (max-width: 750px) {
	.locations-country-card-info-value {
		font-size: 13px;
	}
}

.locations-country-cards-container {
	margin: 0 auto;

	width: 100%;

	text-align: left;
}

.locations-country-cards-container {
	position: relative;
	display: flex;
	margin-left: -4px;
	margin-bottom: 60px;
	padding-bottom: 24px;

	width: calc(100% + 8px);

	text-align: left;
	overflow-x: overlay;
}

.locations-country-cards-container::-webkit-scrollbar {
	border-radius: 4px;

	width: calc(100% - 8px);
	height: 8px;

	background: var(--grey2);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.locations-country-cards-container::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--blue);
}

.locations {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1400px;
	min-height: calc(100vh - 60px);
}

.locations-systems-btn {
	display: flex;
	justify-content: center;
	align-content: center;
	position: relative;
	flex-shrink: 0;

	margin: 0;
	border-radius: 4px;

	width: 100%;
	height: 80px;

	background: var(--grey2);
	background: url(/static/media/initialGalaxySide.56e7e171.png);
	background-size: 150%;
	background-position-x: left;
	background-position-y: center;

	transition: 0.2s;
}

.locations-systems-btn-hover {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);

	border-radius: inherit;

	width: 100%;
	height: 100%;

	background: var(--grey2);

	transition: 0.2s;
	opacity: 0;
}

.locations-systems-btn:hover .locations-systems-btn-hover {
	cursor: pointer;
	opacity: 0.5;
}

.locations-systems-btn-map-icon {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	font-size: 32px;
	vertical-align: middle;
}

.locations-systems-btn-name {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	font-size: 30px;
	vertical-align: middle;
}

@media (max-width: 750px) {
	.locations-systems-btn-name {
		font-size: 22px;
	}
}

.locations-systems-btn-arrow-icon {
	position: relative;
	z-index: 2;
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 0 12px;
	margin-top: 4px;
	font-size: 28px;
	vertical-align: middle;
}

.systems {
	margin: 0 auto;
	width: 100%;
}

.systems-item {
	margin: 0 auto;
	width: calc(100% - 40px);
	max-width: 1400px;
	min-height: calc(100vh - 60px);
}

.lore {
	margin: 0 auto;
	width: 98%;
}

.objects {
	margin: 0 auto;
	width: 98%;
}

:root {
	--background: #070707;
	--blue: #0044ff;

	--grey1: #0f0f0f;
	--grey2: #181818;
	--grey3: #282828;
	--grey4: #3a3a3a;

	--grey1: #010101;
	--grey2: #090909;
	--grey3: #171717;
	--grey4: #1e1e1e;
	--grey5: #2e2e2e;

	--blueGradient: linear-gradient(90deg, #0088ff, #0000ff);

	--entityType0Colour: #0044ff;
	--entityType1Colour: #ff0055;
	--entityType2Colour: #27594d;
}

body {
	margin: 0;
	overflow-y: overlay;
	overflow-x: hidden;

	background: #070707;

	background: var(--background);

	font-family: "Calibri", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

body::-webkit-scrollbar {
	border-radius: 4px;
	width: 7px;
	background: #090909;
	background: var(--grey2);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: #0044ff;
	background: var(--blue);
}

.App {
	text-align: center;
}

