.top-bar {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;

	margin: 0 auto;
	padding: 0;
	border: 0;

	width: 100%;
	height: 80px;
	vertical-align: middle;
}

.top-bar-buttons {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	vertical-align: middle;
}

.top-bar-btn {
	display: flex;
	justify-self: center;
	align-self: center;
	margin: 2px 16px;
	border: 0;
	border-radius: 100%;

	width: 45px;
	min-width: 45px;
	height: 45px;
	min-height: 45px;

	background: rgba(0, 0, 0, 0);
	color: #fff;

	font-size: 1.5rem;
	transition: 0.2s;
	vertical-align: middle;
	cursor: pointer;
}

@media (max-width: 750px) {
	.top-bar-btn {
		margin: 2px 2px;
	}
}

.top-bar-btn:hover {
	background: var(--blue);
	color: #fff;
}

.top-bar-btn svg {
	margin: auto;
	max-width: 100%;
}

.top-bar-btn-home img {
	margin: auto;
	width: 25px;
	height: 25px;

	filter: brightness(0) invert(1);
}
